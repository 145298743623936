/* ---------------------------------- */
/* 色指定 */
/* ---------------------------------- */
$brand_color: #e8381b;

:root {
	--brand-color: $brand_color;
}

.bg-brand {
	background-color: var(--brand-color);
	color: white;
}

.text-brand {
	color: var(--brand-color);
}

.bg-default {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: white;
	}
}

/* ---------------------------------- */
/* ボタン */
/* ---------------------------------- */

.btn-primary {
	background-color: $brand_color;
	border-color: $brand_color;
}

.btn-primary:hover {
	background-color: #c42e17;
	border-color: #c42e17;
}

/* ---------------------------------- */
/* home */
/*
/* スマホ用ヒーロイメージ上書き
/* ---------------------------------- */
@include media-breakpoint-down(md) {
	.index-page {
		.section-hero {
			background-image: url("../img/ill/index_bg.svg");
		}
	}
}

/* ---------------------------------- */
/* 問い合わせ */
/* ---------------------------------- */
// 必須バッジ、オプションバッジ
.badge.badge-required {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.badge-required {
	@extend .bg-danger;
	color: white;
}

.contact-phone-text {
	font-size: 2.5rem;
	font-weight: 500;
}

/* ---------------------------------- */
/* CTA */
/* ---------------------------------- */
section.cta-section {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #525f7f !important;
	}
}

/* ---------------------------------- */
/* 角丸 */
/* ---------------------------------- */
.rounded-xl {
	border-radius: 1rem;
}

/* ---------------------------------- */
/* カルーセル */
/* ---------------------------------- */
#carousel-testimonials .carousel-inner {
	height: 800px;
}

@include media-breakpoint-up(md) {
	#carousel-testimonials .carousel-inner {
		height: 550px;
	}
}

/* ---------------------------------- */
/* 会社概要 */
/* ---------------------------------- */

.tables-2 .table-pricing.company_overview {
	tr:last-child td,
	th {
		border-bottom: 1px solid rgba(94, 114, 228, 0.24);
	}
	th,
	td {
		padding-bottom: 1rem;
	}
}

.picture_frame {
	border: 10px solid;
	border-image: linear-gradient(to right bottom, #e0d9b6, #b4983a) 1 / 10px;
	outline: 3px outset #efe9cc;
	padding: 15px;
	background: #fff;
	box-shadow: 1px 3px 10px #999;

	table {
		th,td {
			padding: .7rem 1rem;
		}
	}
}
