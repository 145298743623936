// =========================================================
// * Argon Design System Pro - v1.0.3
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/argon-design-system-pro
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Google Fonts インポート
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700&display=swap');

// Bootstrap Functions
// @import "bootstrap/functions";
@import "~bootstrap/scss/functions";

// Custom Variables
@import "custom/variables";

// Theme Variables
@import "theme/variables-pro";

// Bootstrap Core
// @import "bootstrap/bootstrap";
@import "~bootstrap/scss/bootstrap";

// Theme Core
@import "theme/theme-pro";

// Custom Variables
@import "custom/styles";
